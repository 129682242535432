<template>
<div>
  <div :class="$style.itemContent">
    <div :class="$style.itemControl">
      <div :class="$style.itemControlContainer">
        <a-button-group>
          <a-button icon="delete" @click="deleteImageHandler" />
          <a-button icon="edit"   @click="replaceImageHandler"/>
        </a-button-group>
      </div>
    </div>
    <img :src="url" alt="Image" />
  </div>
  <div class="text-gray-6">
    <div>{{caption}}</div>
  </div>
</div>
</template>
<script>
export default {
  name: 'editable-image',
  props: {
    url: {
      type: String,
      required: true,
      default: '',
    },
    caption: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    deleteImageHandler(data) {
      this.$emit('delete-image')
    },
    replaceImageHandler(data) {
      this.$emit('replace-image')
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
