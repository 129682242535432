<template>
  <business-media-form />
</template>

<script>
import BusinessMediaForm from "@/components/facilities/BusinessMediaForm";

export default {
  components: {
    BusinessMediaForm,
  },
};
</script>
