<template>
  <a-spin :spinning="loading">
    <div>
      <div class="font-size-36 font-weight-bold text-dark mb-4">Media</div>

      <div class="row">
        <div class="col-md-4">
          <div class="mb-4 w-50 p-3">
            <EditableImage
              v-show="hasUploadedCoverImage"
              :url="media.cover ? media.cover.path : ''"
              caption="Cover Image"
              @replace-image="replaceGenericImage('cover')"
              @delete-image="deleteMediaHandler('cover')"
            />
            <a-upload
              v-show="!hasUploadedCoverImage"
              :file-list="[]"
              :before-upload="beforeCoverUpload"
            >
              <a-button ref="coverImageUploader">
                <a-icon type="upload" /> Upload a cover image
              </a-button>
            </a-upload>
          </div>
        </div>

        <div class="col-md-4">
          <div class="mb-4 w-50 p-3">
            <EditableImage
              v-show="hasUploadedLogo"
              :url="media.logo ? media.logo.path : ''"
              caption="Logo"
              @replace-image="replaceGenericImage('logo')"
              @delete-image="deleteMediaHandler('logo')"
            />
            <a-upload
              v-show="!hasUploadedLogo"
              :file-list="[]"
              :before-upload="beforeLogoUpload"
            >
              <a-button ref="logoImageUploader">
                <a-icon type="upload" /> Upload logo
              </a-button>
            </a-upload>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="mb-4 w-50 p-3">
            <EditableImage
              v-show="media.featured_1 && media.featured_1.path.length > 0"
              :url="media.featured_1 ? media.featured_1.path : ''"
              caption="Featured Image 1"
              @replace-image="replaceGenericImage('featured_1')"
              @delete-image="deleteMediaHandler('featured_1')"
            />
            <a-upload
              v-show="!hasUploadedFeatured1"
              :file-list="[]"
              :before-upload="beforeFeatured1Upload"
            >
              <a-button ref="featured_1_uploader">
                <a-icon type="upload" /> Upload Featured Image
              </a-button>
            </a-upload>
          </div>
        </div>

        <div class="col-md-4">
          <div class="mb-4 w-50 p-3">
            <EditableImage
              v-show="media.featured_2 && media.featured_2.path.length > 0"
              :url="media.featured_2 ? media.featured_2.path : ''"
              caption="Featured Image 2"
              @replace-image="replaceGenericImage('featured_2')"
              @delete-image="deleteMediaHandler('featured_2')"
            />
            <a-upload
              v-show="!hasUploadedFeatured2"
              :file-list="[]"
              :before-upload="beforeFeatured2Upload"
            >
              <a-button ref="featured_2_uploader">
                <a-icon type="upload" /> Upload Featured Image
              </a-button>
            </a-upload>
          </div>
        </div>

        <div class="col-md-4">
          <div class="mb-4 w-50 p-3">
            <EditableImage
              v-show="media.featured_3 && media.featured_3.path.length > 0"
              :url="media.featured_3 ? media.featured_3.path : ''"
              caption="Featured Image 3"
              @replace-image="replaceGenericImage('featured_3')"
              @delete-image="deleteMediaHandler('featured_3')"
            />
            <a-upload
              v-show="!hasUploadedFeatured3"
              :file-list="[]"
              :before-upload="beforeFeatured3Upload"
            >
              <a-button ref="featured_3_uploader">
                <a-icon type="upload" />Upload Featured Image
              </a-button>
            </a-upload>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ProfileMixin from "@/mixins/Profile";

import EditableImage from "@/components/shared/editable-image/index.vue";

export default {
  mixins: [ProfileMixin],
  components: {
    EditableImage,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("media", ["getMediaUrl"]),
    ...mapGetters("auth", ["currentUserType"]),
    hasUploadedCoverImage() {
      return this.media.cover.path.length > 0;
    },
    hasUploadedLogo() {
      return this.media.logo.path.length > 0;
    },
    hasUploadedFeatured1() {
      return (
        this.media &&
        this.media.featured_1 &&
        this.media.featured_1.path.length > 0
      );
    },
    hasUploadedFeatured2() {
      return (
        this.media &&
        this.media.featured_2 &&
        this.media.featured_2.path.length > 0
      );
    },
    hasUploadedFeatured3() {
      return (
        this.media &&
        this.media.featured_3 &&
        this.media.featured_3.path.length > 0
      );
    },
  },
  methods: {
    ...mapActions("media", [
      "DELETE_MEDIA",
      "UPLOAD_MEDIA",
      "DELETE_GENERIC_MEDIA",
      "UPLOAD_GENERIC_MEDIA",
    ]),
    ...mapMutations("tourSight", {
      setTourSightState: "SET_STATE",
    }),
    replaceGenericImage(mediaType) {
      if (mediaType === "cover") {
        this.$refs.coverImageUploader.$el.click();
      } else if (mediaType === "logo") {
        this.$refs.logoImageUploader.$el.click();
      } else if (mediaType === "featured_1") {
        this.$refs.featured_1_uploader.$el.click();
      } else if (mediaType === "featured_2") {
        this.$refs.featured_2_uploader.$el.click();
      } else if (mediaType === "featured_3") {
        this.$refs.featured_3_uploader.$el.click();
      }
    },
    beforeCoverUpload(file) {
      this.handleUpload("cover", file);
      return false;
    },
    beforeLogoUpload(file) {
      this.handleUpload("logo", file);
      return false;
    },
    beforeFeaturedImageUpload(file) {
      this.handleUpload("featured", file);
      return false;
    },
    beforeFeatured1Upload(file) {
      this.handleUpload("featured_1", file);
      return false;
    },
    beforeFeatured2Upload(file) {
      this.handleUpload("featured_2", file);
      return false;
    },
    beforeFeatured3Upload(file) {
      this.handleUpload("featured_3", file);
      return false;
    },
    async deleteMediaHandler(mediaType, data) {
      try {
        this.loading = true;

        const payload = {
          entity_type: this.currentUserType,
          entity_id: this.account._id,
          media_type: mediaType,
        };

        await this.DELETE_GENERIC_MEDIA(payload);

        this.$notification.success({ message: "Media deleted successfully" });
      } catch (error) {
        this.$notification.error({
          message: "Failed to delete media. Try again later",
        });
      } finally {
        this.loading = false;
      }
    },
    async handleUpload(mediaType, fileToUpload) {
      const fileIdentifier = mediaType === "featured" ? "featured" : "media";

      this.loading = true;
      if (fileToUpload) {
        const formData = new FormData();
        formData.append(fileIdentifier, fileToUpload);
        formData.append("media_type", mediaType);
        formData.append("entity_type", this.currentUserType);
        formData.append("entity_id", this.account._id);

        try {
          await this.UPLOAD_GENERIC_MEDIA(formData);

          this.$notification.success({
            message: "Media uploaded successfully",
          });
        } catch (error) {
          console.log(error);
          this.$notification.error({ message: "Media uploaded failed" });
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
